import binance from '../assets/images/binance.svg'
import okx from '../assets/images/okx.svg'
// import bitkub from '../assets/images/bitkub.svg'

const pickBy = (obj) => {
  return Object.keys(obj).filter((k) => obj[k] != null).reduce((a, k) => ({ ...a, [k]: obj[k] }), {})
}

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const formatNumber = (value, decimal) => {
  if (isNaN(value)) {
    return ''
  }
  return new Intl.NumberFormat('en-US', { maximumFractionDigits: decimal }).format(parseFloat(value))
}

const difference = (arr1, arr2) => {
  return (arr1 || []).filter((o) => ((arr2 || []).indexOf(o) === -1))
}

const getUTCText = (time) => {
  return `UTC${(time >= 0) ? '+' : '-'}${Math.abs(time).toString().padStart(2, '0')}`
}

const getExLogo = (ex, w = 96) => {
  let src
  let width
  switch (ex) {
    case 'binance':
      src = binance
      width = w
      break
    case 'okx':
      src = okx
      width = w / 2
      break
    // case 'bitkub':
    //   src = bitkub
    //   width = w
    //   break
    default:
      src = binance
      width = w
      break
  }
  return <img src={src} width={width} alt={ex} />
}

const downloadJsonFile = (obj, name = 'bot') => {
  const fileName = name.replaceAll(/\W/g, '_')
  const data = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(obj, null, 2))
  const download = document.createElement('a')
  download.setAttribute('href', data)
  download.setAttribute('download', fileName + '.json')
  document.body.appendChild(download) // firefox
  download.click()
  download.remove()
}

const parseJsonFile = async (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = (event) => resolve(JSON.parse(event.target.result))
    fileReader.onerror = (error) => reject(error)
    fileReader.readAsText(file)
  })
}

const urlValidator = (string) => {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}

export {
  pickBy,
  sleep,
  formatNumber,
  difference,
  getUTCText,
  getExLogo,
  downloadJsonFile,
  parseJsonFile,
  urlValidator
}