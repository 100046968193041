import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Form as AntdForm, Card, Button, Input, Switch, Skeleton } from 'antd'
import { SaveOutlined, CloseOutlined } from '@ant-design/icons'

import { collection, doc, getDoc, setDoc, addDoc } from 'firebase/firestore'

import { useAuth } from '../../contexts/AuthContextProvider'
import { pickBy } from '../../utils/tools'

import './Setting.css'

const Form = () => {
  const navigate = useNavigate()
  const { db, uid } = useAuth()
  const { tid } = useParams()
  const [btnLoading, setBtnLoading] = useState(false)
  const [isLine, setIsLine] = useState(false)

  const [tokenForm] = AntdForm.useForm()

  const mode = tid ? 'Edit' : 'Create'

  const { value, loading } = useAsync(async () => {
    if (!tid) {
      return {
        uid: uid
      }
    }

    const snap = await getDoc(doc(db, 'tokens', tid))
    const data = snap.data()
    setIsLine(data.line)
    return {
      ...data,
      id: snap.id,
    }
  }, [tid])

  const handleSubmit = async (formData) => {
    try {
      setBtnLoading(true)
      const data = pickBy(formData)
      if (tid) {
        await setDoc(doc(db, 'tokens', tid), data, { merge: true })
      } else {
        await addDoc(collection(db, 'tokens'), data)
      }
      setBtnLoading(false)
      navigate('/admin/settings/tokens')
    } catch (error) {
      setBtnLoading(false)
    }
  }

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='setting-page'>
      <Card
        title={`${mode} Alert Token`}
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <AntdForm
          labelCol={{ span: 4 }}
          onFinish={handleSubmit}
          initialValues={value}
          form={tokenForm}
          name='tokenForm'
        >
          <AntdForm.Item name='uid' hidden={true}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='name' label='Name' rules={[{ required: true, message: 'Please input Name!' }]}>
            <Input />
          </AntdForm.Item>
          <AntdForm.Item name='ln' label='LINE Notify' valuePropName='checked'>
            <Switch checkedChildren='Yes' unCheckedChildren='No' onChange={(v) => setIsLine(v)} />
          </AntdForm.Item>
          {(isLine) && (
            <AntdForm.Item name='lntkn' label='Line Token' labelCol={{ span: 6 }}>
              <Input />
            </AntdForm.Item>
          )}
          <div style={{ textAlign: 'center' }}>
            <Button loading={btnLoading} style={{ width: 300, borderRadius: '4px', height: 40, marginTop: 20 }} onClick={() => tokenForm.submit()} type='primary'><SaveOutlined />Submit</Button>
            <Button loading={btnLoading} style={{ borderRadius: '4px', height: 40, marginTop: 20, marginLeft: 20 }} onClick={() => navigate(-1)}><CloseOutlined />Close</Button>
          </div>
        </AntdForm>
      </Card>
    </div>
  )
}

export default Form
