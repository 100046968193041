import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAsync } from 'react-use'

import { Card, Table, Button, Popconfirm, Skeleton } from 'antd'
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons'

import { httpsCallable } from 'firebase/functions'

import { useAuth } from '../../contexts/AuthContextProvider'
import { useLayoutContext } from '../../contexts/LayoutContextProvider'
import { getExLogo } from '../../utils/tools'

import './Setting.css'

const KeyList = () => {
  const navigate = useNavigate()
  const { functions, uid } = useAuth()
  const { getPageNo, onPageChange, setData } = useLayoutContext()

  const [updateCount, setUpdateCount] = useState(0)

  const { value, loading } = useAsync(async () => {
    const getKeys = httpsCallable(functions, 'getKeys-getKeys')
    const { data } = await getKeys({ uid: uid })
    return data
  }, [updateCount])

  const handleDelete = async (id) => {
    try {
      const deleteKey = httpsCallable(functions, 'deleteKey-deleteKey')
      await deleteKey({ uid: uid, kid: id })
      setData('keys', null)
      setUpdateCount(updateCount + 1)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name'
    },
    {
      title: 'Exchange',
      dataIndex: 'ex',
      render: text => getExLogo(text)
    },
    {
      title: 'Operation',
      dataIndex: 'Operation',
      width: 250,
      render: (_, row) => (
        <div>
          <Button style={{ borderRadius: '4px' }} type='primary' size='middle' onClick={() => navigate(`/admin/settings/keys/${row.id}/edit`)}><EditOutlined /></Button>
          <Popconfirm
            title={`Are you sure to delete ${row.name} key?`}
            onConfirm={() => handleDelete(row.id)}
            okText='Yes'
            cancelText='No'
          >
            <Button style={{ borderRadius: '4px', marginLeft: '16px' }} type='primary' size='middle' danger><DeleteOutlined /></Button>
          </Popconfirm>
        </div>
      )
    }
  ]

  if (loading) return <Skeleton></Skeleton>
  return (
    <div className='setting-page'>
      <Card
        title='API Key List'
        styles={{
          header: { fontWeight: 'bold', fontSize: '20px', lineHeight: '23px', backgroundColor: '#36cfc9', color: '#fdfdfd' },
          body: { boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.1)', borderRadius: '0 0 4px 4px' }
        }}
      >
        <div style={{ textAlign: 'right', marginBottom: 20 }}>
          <Button style={{ borderRadius: '4px' }} onClick={() => navigate('/admin/settings/keys/create')} type='primary'><PlusOutlined />Add New Key</Button>
        </div>
        <Table
          bordered
          rowKey='id'
          columns={columns}
          dataSource={value}
          pagination={{
            total: value?.length ?? 0,
            current: getPageNo('keyList'),
            onChange(page) {
              onPageChange('keyList', page)
            }
          }}
        />
      </Card>
    </div>
  )
}

export default KeyList
